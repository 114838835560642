// ContextMenu.js
import React from 'react';
import './ContextMenu.css'; // Assume you have some basic styling in here

const ContextMenu = ({ x, y, options, isVisible, onRequestClose }) => {
  if (!isVisible) return null;

  const handleActionClick = action => {
    action();
    onRequestClose(); // This will close the menu after the action
  };

  return (
    <ul className="context-menu" style={{ top: y, left: x }}>
      {options.map((option, index) => (
        <li
          key={index}
          className="context-menu-item"
          onClick={() => handleActionClick(option.action)}
        >
          {option.label}
        </li>
      ))}
    </ul>
  );
};

export default ContextMenu;
