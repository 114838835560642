import React, { useEffect, useState } from 'react';
import MarkdownPreview from './MarkdownPreview';
import Toolbar from './Toolbar';

import { publicApiCall } from '../utils/api';
import { useNavigate } from 'react-router-dom';

import './PublicDocumentView.css';

function PublicDocumentView({ username, directory, document }) {
  const [activeFile, setActiveFile] = useState(null);
  const [isMarkdown, setIsMarkdown] = useState(false);
  const navigate = useNavigate();

  const notFound = () => {
    navigate('/404');
  };

  useEffect(() => {
    const loadDocumentByName = async () => {
      if (directory && document) {
        try {
          let endpoint = `/${encodeURIComponent(
            username
          )}/folders/${encodeURIComponent(
            directory
          )}/documents/${encodeURIComponent(document)}`;

          const data = await publicApiCall('get', endpoint);

          if (data) {
            setActiveFile(data);
            setIsMarkdown(data.title.endsWith('.md'));
          }
        } catch (error) {
          console.error('Error loading document:', error);
          if (error.response && error.response.status === 404) {
            notFound();
          }
        }
      } else {
        notFound();
      }
    };

    loadDocumentByName();
  }, [username, directory, document, navigate]);

  return (
    <>
      <Toolbar />
      <div className="public-document-layout mt-5">
        <div className="public-document-header">
          <img
            src="https://avatars.githubusercontent.com/corywilkerson"
            alt="User's avatar"
            className="user-avatar"
          />
          <span className="user-info">
            @{username} {activeFile?.folder.name}/{activeFile?.title}
          </span>
        </div>
        <div className="public-document-content">
          <div className="content-body">
            {isMarkdown ? (
              <MarkdownPreview file={activeFile} />
            ) : (
              <pre>{activeFile?.content}</pre>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PublicDocumentView;
