import React, { useContext, useEffect, useState } from 'react';
import {
  CheckIcon,
  CodeIcon,
  CodeSquareIcon,
  EyeIcon,
  LockIcon,
  MarkdownIcon,
  TrashIcon,
  XIcon,
  XCircleIcon,
} from '@primer/octicons-react';
import './EditorTopRibbon.css';
import MarkdownToolbar from './MarkdownToolbar';
import FileExplorerContext from '../contexts/FileExplorerContext';

const EditorTopRibbon = ({
  currentTab,
  file,
  isPermissionsEditMode,
  onClose,
  onTabChange,
  onTogglePermissions,
  onToolbarItemClick,
  onSavePermissions,
}) => {
  const { handleFileDelete, handleFileRename } =
    useContext(FileExplorerContext);

  const [isEditing, setIsEditing] = useState(false);
  const [newTitle, setNewTitle] = useState(file.title);

  useEffect(() => {
    setNewTitle(file.title);
  }, [file]);

  const handleDoubleClick = () => setIsEditing(true);

  const handleBlur = () => {
    handleFileRename(file.id, newTitle);
    setIsEditing(false);
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleFileRename(file.id, newTitle);
      setIsEditing(false);
    } else if (e.key === 'Escape') {
      setNewTitle(file.title);
      setIsEditing(false);
    }
  };

  const handleCloseClick = () => onClose(file.id);

  const handleDeleteClick = () => handleFileDelete(file.id);

  const isMarkdown = file.title.endsWith('.md');

  return (
    <div className="editor-top-ribbon">
      {isPermissionsEditMode ? (
        <>
          <span className="permissions-title-text">
            <LockIcon /> Permissions - {file.title}
          </span>
          <div className="permissions-actions">
            <button className="action-icon" onClick={onSavePermissions}>
              <CheckIcon size={14} />
            </button>
            <button
              className="action-icon"
              onClick={() => onTogglePermissions()}
            >
              <XIcon size={14} />
            </button>
          </div>
        </>
      ) : (
        <>
          {isMarkdown ? (
            <MarkdownIcon className="mr-2" />
          ) : (
            <CodeSquareIcon className="mr-2" />
          )}
          <div className="file-title">
            {isEditing ? (
              <input
                value={newTitle}
                onChange={e => setNewTitle(e.target.value)}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                autoFocus
                className="title-input"
              />
            ) : (
              <span
                className="title-text"
                title={file.title}
                onDoubleClick={handleDoubleClick}
              >
                {file.title}
              </span>
            )}
            {isMarkdown && (
              <div className="tabs">
                <button
                  className={currentTab === 'edit' ? 'active' : ''}
                  onClick={() => onTabChange('edit')}
                >
                  <CodeIcon size={14} />
                  &nbsp;<span className="button-text">Edit</span>
                </button>
                <button
                  className={currentTab === 'preview' ? 'active' : ''}
                  onClick={() => onTabChange('preview')}
                >
                  <EyeIcon size={14} />
                  &nbsp;<span className="button-text">Preview</span>
                </button>
              </div>
            )}
            {isMarkdown && (
              <MarkdownToolbar
                currentTab={currentTab}
                onToolbarItemClick={onToolbarItemClick}
              />
            )}
          </div>
          <div className="actions">
            <button className="action-icon" onClick={handleCloseClick}>
              <XCircleIcon />
            </button>
            <div
              style={{
                borderLeft: '1px solid #000',
                height: '16px',
                margin: '10px',
              }}
            ></div>
            <button className="action-icon" onClick={handleDeleteClick}>
              <TrashIcon />
            </button>
            <button className="action-icon" onClick={onTogglePermissions}>
              <LockIcon />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default EditorTopRibbon;
