import React, { useEffect, useState, useContext, memo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import EditorShell from './EditorShell';
import NotificationModal from './components/NotificationModal';
import PublicDocumentView from './components/PublicDocumentView';

import {
  NotificationProvider,
  NotificationContext,
} from './contexts/NotificationContext';
import { FileExplorerProvider } from './contexts/FileExplorerContext';

import { isAuthenticated, getLoggedInUsername } from './utils/auth';

function DocumentHandler() {
  const { username, directory, document } = useParams();
  const navigate = useNavigate();

  const [isOwner, setIsOwner] = useState(false);
  const [loading, setLoading] = useState(true);

  // Redirect to default document if no directory or document is specified
  useEffect(() => {
    if (!directory || !document) {
      navigate(`/${username}/home/README`, { replace: true });
    }
  }, [username, directory, document, navigate]);

  // Check ownership, this is really just used to pick which component we'll render.
  // The server will still manage permission checks irrespective of what happens here.
  useEffect(() => {
    const checkOwnership = async () => {
      if (isAuthenticated()) {
        const loggedInUsername = getLoggedInUsername();
        setIsOwner(loggedInUsername === username);
      }

      setLoading(false);
    };

    checkOwnership();
  }, [username]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <NotificationProvider>
      <MemoizedDocumentContent
        isOwner={isOwner}
        directory={directory}
        document={document}
        username={username}
      />
    </NotificationProvider>
  );
}

const DocumentContent = ({ isOwner, directory, document, username }) => {
  const { notification } = useContext(NotificationContext);

  return (
    <>
      {isOwner ? (
        <FileExplorerProvider>
          <EditorShell directory={directory} document={document} />
        </FileExplorerProvider>
      ) : (
        <PublicDocumentView
          username={username}
          directory={directory}
          document={document}
        />
      )}
      {notification && <NotificationModal {...notification} />}
    </>
  );
};

const MemoizedDocumentContent = memo(DocumentContent);

export default DocumentHandler;
