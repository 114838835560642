import React, { createContext, useState } from 'react';

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState({
    messages: [],
    action: null,
  });

  const showInfoNotification = (messages, action = null) => {
    showNotification(messages, 'info', action);
  };

  const showNotification = (messages, type = 'info', action = null) => {
    setNotification({
      messages,
      type,
      action,
    });

    if (!action) {
      setTimeout(hideNotification, 5000);
    }
  };

  const hideNotification = () => {
    setNotification({ messages: [], action: null });
  };

  return (
    <NotificationContext.Provider
      value={{
        notification,
        showInfoNotification,
        showNotification,
        hideNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
