import React from 'react';
import './Login.css';

function Login() {
  const handleLogin = () => {
    window.location.href = `https://github.com/login/oauth/authorize?client_id=${process.env.REACT_APP_GITHUB_CLIENT_ID}&scope=read:user&redirect_uri=${process.env.REACT_APP_GITHUB_REDIRECT_URI}`;
  };

  return (
    <div className="login-container">
      <img src="/images/avatar.webp" alt="Avatar" className="avatar" />

      <button onClick={handleLogin} className="login-button">
        Login with GitHub
      </button>
    </div>
  );
}

export default Login;
