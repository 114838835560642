import axiosInstance from './axiosInstance';
import axios from 'axios';
import jsYaml from 'js-yaml';

import { isTokenExpired, getNewAccessToken } from './axiosInstance';


// Create a fetch wrapper
async function fetchWithTokenRefresh(input, init) {
  let token = localStorage.getItem('token');

  // Check if the access token is expired
  if (isTokenExpired(token)) {
    token = await getNewAccessToken();
    if (!token) {
      throw new Error('Token refresh failed');
    }
  }

  // Attach token to headers
  init.headers = {
    ...init.headers,
    Authorization: `Bearer ${token}`,
  };

  return fetch(input, init);
}

export const apiUrl = (path = '') => {
  const protocol = process.env.REACT_APP_SERVER_PROTOCOL;
  const host = process.env.REACT_APP_SERVER_HOST;
  const port = process.env.REACT_APP_SERVER_PORT;

  const baseUrl = port
    ? `${protocol}://${host}:${port}`
    : `${protocol}://${host}`;

  // Append the path if it's provided, otherwise return the base URL
  return path ? `${baseUrl}${path}` : baseUrl;
};

export const apiStreamCall = async function* (
  method,
  url,
  data = null,
  signal
) {
  try {
    const response = await fetchWithTokenRefresh(apiUrl(url), {
      method,
      body: JSON.stringify(data),
      signal,
      headers: { 'Content-Type': 'application/json' },
    });

    const reader = response.body.getReader();
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      yield new TextDecoder('utf-8').decode(value);
    }
  } catch (error) {
    console.error('Error with the API call:', error);
    throw error;
  }
};

export const publicApiCall = async (method, url, data = null) => {
  try {
    const response = await axios({
      method,
      url: apiUrl(url),
      data,
    });
    return response.data;
  } catch (error) {
    console.error('Error with the API call:', error);
    throw error;
  }
};

export const apiCall = async (method, url, data = null) => {
  try {
    const response = await axiosInstance({
      method,
      url: `/${url}`,
      data,
    });
    return response.data;
  } catch (error) {
    console.error('Error with the API call:', error);
    throw error;
  }
};

export const convertYAMLToPermissions = yamlStr => {
  try {
    const permissionsArray = jsYaml.load(yamlStr);
    return permissionsArray;
  } catch (error) {
    console.error('Error converting YAML to permissions:', error);
    return [];
  }
};

export const convertPermissionsToYAML = permissionsArray => {
  try {
    const yamlStr = jsYaml.dump(permissionsArray);
    return yamlStr;
  } catch (error) {
    console.error('Error converting permissions to YAML:', error);
    return '';
  }
};
