import React, { createContext, useContext, useState } from 'react';
import { NotificationContext } from './NotificationContext';

import { apiCall } from '../utils/api';
import { getLoggedInUsername } from '../utils/auth';
import { useNavigate } from 'react-router-dom';

const FileExplorerContext = createContext();

export const useFileExplorer = () => useContext(FileExplorerContext);

export function FileExplorerProvider({ children }) {
  const { showNotification } = useContext(NotificationContext);

  const navigate = useNavigate();

  const [activeFile, setActiveFile] = useState(null);
  const [currentUser, setCurrentUser] = useState(getLoggedInUsername());
  const [folders, setFolders] = useState([]);
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [openFolders, setOpenFolders] = useState([]);

  const fetchFolders = async () => {
    try {
      const data = await apiCall('get', 'folders');
      setFolders(data);
    } catch (error) {
      console.error('Error fetching folders', error);
    }
  };

  const withFetchFoldersAndNavigate =
    action =>
    async (...args) => {
      const result = await action(...args);
      await fetchFolders();
      navigate(result);
      return result;
    };

  const withFetchFolders =
    action =>
    async (...args) => {
      const result = await action(...args);
      await fetchFolders();
      return result;
    };

  const handleFileCreate = withFetchFoldersAndNavigate(async folder => {
    const newFile = await apiCall('post', 'documents', { folderId: folder.id });

    return `/${currentUser}/${newFile.folder.name}/${newFile.title}`;
  });

  const handleFileDelete = withFetchFolders(async file => {
    try {
      await apiCall('delete', `documents/${file.id}`);

      // Delete the active file if it's set...
      if (activeFile && activeFile.id === file.id) {
        setActiveFile(null);
        navigate(`/${currentUser}`);
      }
    } catch (error) {
      console.error('Error deleting the file:', error);
    }
  });

  const handleFileMove = withFetchFoldersAndNavigate(
    async (fileId, newFolderId) => {
      const movedFile = await apiCall('patch', `documents/${fileId}/move`, {
        folderId: newFolderId,
      });
      return `/${currentUser}/${movedFile.folder.name}/${movedFile.title}`;
    }
  );

  const handleFileOpen = async file => {
    // setNotification({ message: 'Error opening file', type: 'error' });

    navigate(`/${currentUser}/${file.folder.name}/${file.title}`);
  };

  const handleFileRename = withFetchFoldersAndNavigate(
    async (fileId, newTitle) => {
      const renamedFile = await apiCall('patch', `documents/${fileId}/rename`, {
        title: newTitle,
      });
      return `/${currentUser}/${renamedFile.folder.name}/${renamedFile.title}`;
    }
  );

  const handleFolderCreate = withFetchFolders(async () => {
    try {
      await apiCall('post', 'folders');
    } catch (error) {
      console.error('Error creating new folder:', error);
    }
  });

  const handleFolderDelete = withFetchFolders(async folder => {
    try {
      await apiCall('delete', `folders/${folder.id}`);
    } catch (error) {
      console.error('Error delting folder:', error);
    }
  });

  const handleFolderRename = withFetchFoldersAndNavigate(
    async (folderId, newName) => {
      try {
        await apiCall('patch', `folders/${folderId}/rename`, { name: newName });
        return `/${currentUser}/${newName}/${activeFile.title}`;
      } catch (error) {
        showNotification(
          ['Something went wrong.', 'Folders need to have unique names.'],
          'error'
        );
      }
    }
  );

  const handleFolderSelect = folderId => {
    setSelectedFolderId(prevFolderId =>
      prevFolderId === folderId ? null : folderId
    );
  };

  const value = {
    activeFile,
    setActiveFile,
    folders,
    setFolders,
    openFolders,
    setOpenFolders,
    currentUser,
    handleFileOpen,
    handleFileCreate,
    handleFileDelete,
    handleFileMove,
    handleFileRename,
    handleFolderCreate,
    handleFolderDelete,
    handleFolderRename,
    handleFolderSelect,
    selectedFolderId,
    setSelectedFolderId,
  };

  return (
    <FileExplorerContext.Provider value={value}>
      {children}
    </FileExplorerContext.Provider>
  );
}

export default FileExplorerContext;
