import React, { useState } from 'react';
import Drawer from './Drawer';
import './Toolbar.css';

import { isAuthenticated } from '../utils/auth';

const Toolbar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleAvatarClick = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <div className="toolbar">
      <img
        src="/images/avatar.webp"
        alt="Avatar"
        className="avatar-icon"
        onClick={handleAvatarClick}
      />
      <span className="toolbar-text">REMY</span>
      {isAuthenticated() && isDrawerOpen && (
        <Drawer onClose={() => setIsDrawerOpen(false)} />
      )}{' '}
    </div>
  );
};

export default Toolbar;
