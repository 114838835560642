import axios from 'axios';

const apiUrl = (path = '') => {
  const protocol = process.env.REACT_APP_SERVER_PROTOCOL;
  const host = process.env.REACT_APP_SERVER_HOST;
  const port = process.env.REACT_APP_SERVER_PORT;

  const baseUrl = port
    ? `${protocol}://${host}:${port}`
    : `${protocol}://${host}`;

  // Append the path if it's provided, otherwise return the base URL
  return path ? `${baseUrl}${path}` : baseUrl;
};

// Function to get a new access token using the refresh token
export async function getNewAccessToken() {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    const response = await axios.post(apiUrl('/auth/refresh'), {
      refreshToken: refreshToken,
    });

    // Update local storage with new tokens
    localStorage.setItem('token', response.data.accessToken);
    localStorage.setItem('refreshToken', response.data.refreshToken);

    return response.data.accessToken;
  } catch (error) {
    console.error('Error refreshing token:', error);
    // Handle token refresh errors (e.g., redirect to login)
    return null;
  }
}

// Function to check if the token is expired
export function isTokenExpired(token) {
  if (!token) return true;

  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return Date.now() >= payload.exp * 1000;
  } catch (error) {
    return true;
  }
}

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: apiUrl(),
});

// Request Interceptor
axiosInstance.interceptors.request.use(
  async config => {
    let token = localStorage.getItem('token');

    // Check if the access token is expired
    if (isTokenExpired(token)) {
      token = await getNewAccessToken();
      if (!token) {
        throw new axios.Cancel('Token refresh failed');
      }
    }

    // Attach token to headers
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  error => Promise.reject(error)
);

// Optionally add a response interceptor
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    // Add logic to handle errors in responses
    return Promise.reject(error);
  }
);

export default axiosInstance;
