import React, { useEffect, useRef } from 'react';

import { apiCall, publicApiCall } from '../utils/api';

const MarkdownPreview = ({ file }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const apiMethod = token ? apiCall : publicApiCall;
    
    apiMethod('get', `/documents/${file.id}/render`)
      .then(res => {
        const iframe = iframeRef.current;
        const doc = iframe.contentDocument;
        doc.open();
        doc.write('<link rel="stylesheet" href="/markdown.css">');
        doc.write(res.data);
        doc.body.style = {
          fontFamily:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
          backgroundColor: 'white',
          color: '#24292e',
        };
        doc.close();
      })
      .catch(error => {
        console.error('Error with the API call:', error);
      });
  }, [file]);

  return (
    <iframe
      ref={iframeRef}
      style={{
        height: '500px',
        width: '100%',
        border: 'none',
        overflow: 'auto',
      }}
      title="Markdown Preview"
    />
  );
};

export default MarkdownPreview;
