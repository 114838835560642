import { jwtDecode } from 'jwt-decode';

import { TOKEN_KEY, REFRESH_TOKEN_KEY } from './../constants';

export const isAuthenticated = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

  if (!token || !refreshToken) {
    return false;
  }

  try {
    const { exp } = jwtDecode(token);
    const { exp: refreshExp } = jwtDecode(refreshToken);

    // Check if either the access token or the refresh token is valid
    return Date.now() < exp * 1000 || Date.now() < refreshExp * 1000;
  } catch (error) {
    return false;
  }
};

export const getLoggedInUsername = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (!token) {
    return null;
  }

  try {
    const decoded = jwtDecode(token);
    return decoded.username;
  } catch (error) {
    return null;
  }
};
