import React, { useContext, useEffect, useState, useRef } from 'react';
import AceEditor from 'react-ace';
import { Range } from 'ace-builds';

import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-searchbox';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-markdown';
import 'ace-builds/src-noconflict/mode-ruby';
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-textmate';

import { apiStreamCall } from '../utils/api';

import { NotificationContext } from '../contexts/NotificationContext';

import './Editor.css';
import { XIcon } from '@primer/octicons-react';

const Editor = ({
  applyFunction,
  file,
  mode,
  nullifyApplyFunction,
  onContentChange,
}) => {
  const { hideNotification, showInfoNotification } =
    useContext(NotificationContext);

  const [isLoading, setIsLoading] = useState(false);
  const [showInput, setShowInput] = useState(true);

  const editorRef = useRef();
  const selectedTextRef = useRef(null);

  // When this is happening we should lock the IDE state
  const [llmInput, setLlmInput] = useState('');

  const [indentMode, setIndentMode] = useState('spaces');
  const [indentWidth, setIndentWidth] = useState(2);
  const [wrapMode, setWrapMode] = useState(false);

  let abortController = new AbortController();

  const handleCancel = () => {
    // Abort the API request
    abortController.abort();

    // Create a new AbortController for future requests
    abortController = new AbortController();

    setIsLoading(false);
    setShowInput(true);

    hideNotification();
  };

  const triggerLlmQueryCommand = {
    name: 'triggerLlmQuery',
    bindKey: { win: 'Enter', mac: 'Enter' },
    exec: editor => {
      const cursorPosition = editor.getCursorPosition();
      const currentLine = editor.session.getLine(cursorPosition.row);
      const queryPattern = /^\? (.+)/;
      const match = queryPattern.exec(currentLine);

      if (match) {
        const query = match[1];
        setLlmInput(query);
        setIsLoading(true);

        // Correctly position the cursor at the end of the current line
        const lineEndPosition = editor.session.getLine(
          cursorPosition.row
        ).length;
        editor.moveCursorTo(cursorPosition.row, lineEndPosition);

        // Insert two new lines right after the query to ensure there's a blank line
        // before the LLM response starts
        editor.insert('\n\n');

        // Move the cursor to where the LLM response should begin
        editor.moveCursorTo(cursorPosition.row + 2, 0);

        // Now invoke the LLM with the query
        handleLlmSubmit(query); // Make sure this function is prepared to handle the query

        return true;
      }

      return false;
    },
  };

  useEffect(() => {
    const editorInstance = editorRef.current && editorRef.current.editor;
    if (editorInstance) {
      // Add the command to the editor
      editorInstance.commands.addCommand(triggerLlmQueryCommand);
    }

    // Cleanup function to remove the command when the component unmounts or reloads
    return () => {
      if (editorInstance) {
        editorInstance.commands.removeCommand(triggerLlmQueryCommand.name);
      }
    };
  }, [editorRef, triggerLlmQueryCommand]);

  const handleLlmSubmit = async (query = null) => {
    const editorInstance = editorRef.current && editorRef.current.editor;

    showInfoNotification(
      ['Remy is doing biz with the llm, just wait a sec...'],
      handleCancel
    );

    setIsLoading(true);
    setShowInput(false);

    try {
      for await (const chunk of apiStreamCall(
        'POST',
        '/llm/chat-response',
        {
          editorContent: editorInstance.getValue(),
          input: query ? query : llmInput,
        },
        abortController.signal
      )) {
        // Update the UI with the new data
        updateUI(chunk);
      }
    } catch (error) {
      // Handle error if needed
    } finally {
      // Add a new line after the LLM response
      editorInstance.insert('\n');

      setIsLoading(false);
      setShowInput(true);

      hideNotification();
    }
  };

  const updateUI = newData => {
    const editorInstance = editorRef.current && editorRef.current.editor;

    if (editorInstance) {
      const session = editorInstance.getSession();
      const cursorPosition = editorInstance.getCursorPosition();
      // Insert the new data at the current cursor position
      editorInstance.session.insert(cursorPosition, newData);

      // After insertion, calculate the new position based on the added content
      const addedLines = newData.split('\n').length;
      const newLinePosition = cursorPosition.row + addedLines;

      // Move the cursor to the end of the inserted content
      editorInstance.moveCursorTo(newLinePosition, 0);

      // Ensure the newly inserted line is visible in the viewport
      editorInstance.scrollToLine(newLinePosition, true, true, () => {});

      // Optionally, bring focus back to the editor
      editorInstance.focus();
    }
  };

  const yamlAutocompleteRules = {
    getCompletions: function (editor, session, pos, prefix, callback) {
      const wordList = [
        { word: 'userId: ', meta: 'field' },
        { word: 'role: editor', meta: 'value' },
        { word: 'role: commenter', meta: 'value' },
        { word: 'role: viewer', meta: 'value' },
        { word: 'accessLevel: specific', meta: 'value' },
        { word: 'accessLevel: link', meta: 'value' },
      ];

      callback(
        null,
        wordList.map(entry => ({
          caption: entry.word,
          value: entry.word,
          meta: entry.meta,
        }))
      );
    },
  };

  useEffect(() => {
    if (applyFunction) {
      const editorInstance = editorRef.current && editorRef.current.editor;

      if (editorInstance) {
        const selection = editorInstance.getSelection();
        let selectedRange;

        if (selectedTextRef.current != null) {
          selectedRange = selection.getRange();
        } else {
          const cursorPosition = editorInstance.getCursorPosition();
          selectedRange = new Range(
            cursorPosition.row,
            cursorPosition.column,
            cursorPosition.row,
            cursorPosition.column
          );
        }

        const updatedText = applyFunction(selectedTextRef.current || '');
        editorInstance.session.replace(selectedRange, updatedText);

        nullifyApplyFunction();
      }
    }
  }, [applyFunction]);

  useEffect(() => {
    const editorInstance = editorRef.current && editorRef.current.editor;
    if (editorInstance && mode === 'yaml') {
      editorInstance.completers = [yamlAutocompleteRules];
    }
  }, [mode]);

  const getEditorMode = () => {
    if (mode) return mode;
    if (file && file.title) return getMode(file.title);
    return 'text';
  };

  const getMode = filename => {
    const extension = filename.split('.').pop();
    switch (extension) {
      case 'js':
        return 'javascript';
      case 'md':
        return 'markdown';
      case 'rb':
        return 'ruby';
      default:
        return 'text';
    }
  };

  useEffect(() => {
    const editorInstance = editorRef.current && editorRef.current.editor;
    if (editorInstance) {
      editorInstance.focus();
    }
  }, [file]);

  const handleEditorChange = newValue => {
    if (onContentChange) {
      onContentChange(newValue);
    }
  };

  const handleTextSelection = text => {
    if (!text) {
      selectedTextRef.current = null;
    } else {
      selectedTextRef.current = text;
    }
  };

  const formattingProps = {
    mode: getEditorMode(),
    theme: 'textmate',
    name: 'ACE_EDITOR',
    style: { width: '100%', height: '100%', lineHeight: '1.6' },
    tabSize: indentWidth,
    useSoftTabs: indentMode === 'spaces',
    wrapEnabled: wrapMode,
    editorProps: { $blockScrolling: true },
    setOptions: { useWorker: false },
    enableBasicAutocompletion: true,
    enableLiveAutocompletion: true,
    readOnly: isLoading,
  };

  const behaviorProps = {
    ref: editorRef,
    onChange: handleEditorChange,
    value: file ? file.content : '',
    editorProps: { $blockScrolling: true },
    setOptions: { useWorker: false },
    onCursorChange: selection => {
      const selectedText = editorRef.current.editor.session.getTextRange(
        selection.getRange()
      );
      handleTextSelection(selectedText);
    },
  };

  return (
    <>
      <div className="editor-container">
        <div className="editor-content">
          {isLoading && <div className="editor-overlay"></div>}
          <AceEditor {...formattingProps} {...behaviorProps} />
        </div>
        <div
          className="editor-status-bar"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#f5f5f5',
            padding: '5px',
          }}
        >
          {/* Input field and buttons */}
          {file && file.title.endsWith('.chat') && (
            <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
              <input
                type="text"
                value={llmInput}
                onChange={e => setLlmInput(e.target.value)}
                disabled={isLoading}
                style={{
                  flex: 1,
                  animation: isLoading
                    ? 'pulse 1.5s infinite ease-in-out'
                    : 'none',
                }}
              />
              <button
                className={`btn ${isLoading ? 'btn-danger' : ''}`}
                onClick={isLoading ? handleCancel : handleLlmSubmit}
              >
                {isLoading ? <XIcon size={16} /> : 'Submit'}
              </button>
            </div>
          )}
          <div style={{ display: 'flex', gap: '5px' }}>
            <select
              value={indentMode}
              onChange={e => setIndentMode(e.target.value)}
              style={{
                border: '1px solid #6ea1db',
                borderRadius: '2px',
                fontSize: '0.8em',
                padding: '2px',
              }}
            >
              <option value="spaces">Spaces</option>
              <option value="tabs">Tabs</option>
            </select>
            <select
              value={indentWidth}
              onChange={e => setIndentWidth(Number(e.target.value))}
              style={{
                border: '1px solid #6ea1db',
                borderRadius: '2px',
                fontSize: '0.8em',
                padding: '2px',
              }}
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={4}>4</option>
              <option value={8}>8</option>
            </select>
            <select
              value={wrapMode}
              onChange={e => setWrapMode(e.target.value === 'true')}
              style={{
                border: '1px solid #6ea1db',
                borderRadius: '2px',
                fontSize: '0.8em',
                padding: '2px',
              }}
            >
              <option value="true">On</option>
              <option value="false">Off</option>
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default Editor;
