import React, { useContext, useEffect, useRef, useState } from 'react';

import {
  FileDirectoryOpenFillIcon,
  FileDirectoryFillIcon,
  FileIcon,
  FoldIcon,
  PlusIcon,
} from '@primer/octicons-react';

import ContextMenu from './ContextMenu';
import FileExplorerContext from '../contexts/FileExplorerContext';

import './FileExplorer.css';

const FileExplorer = ({ onFolderSelect }) => {
  const {
    activeFile,
    folders,
    openFolders,
    setOpenFolders,
    handleFileCreate,
    handleFileDelete,
    handleFileMove,
    handleFileOpen,
    handleFileRename,
    handleFolderCreate,
    handleFolderDelete,
    handleFolderRename,
    handleFolderSelect,
    selectedFolderId,
  } = useContext(FileExplorerContext);

  const draggingFile = useRef(null);
  const [hasSetHomeFolderOpen, setHasSetHomeFolderOpen] = useState(false);

  useEffect(() => {
    if (selectedFolderId && !openFolders.includes(selectedFolderId)) {
      setOpenFolders(prevOpenFolders => [...prevOpenFolders, selectedFolderId]);
    }
  }, [selectedFolderId]);

  const [editingFolderId, setEditingFolderId] = useState(null);
  const [editingFileId, setEditingFileId] = useState(null);

  const [contextMenu, setContextMenu] = useState({
    isVisible: false,
    position: { x: 0, y: 0 },
    options: [],
    target: null,
  });

  const [contextMenuTarget, setContextMenuTarget] = useState(null);

  // Manage file/folder name edits locally to the component.
  const handleEditFile = file => {
    setEditingFileId(file.id);
  };

  const handleEditFolder = folder => {
    setEditingFolderId(folder.id);
  };

  useEffect(() => {
    if (contextMenu.isVisible) {
      const closeMenu = event => {
        if (!event.target.closest('.context-menu')) {
          hideContextMenu();
        }
      };

      document.addEventListener('mousedown', closeMenu);
      return () => {
        document.removeEventListener('mousedown', closeMenu);
      };
    }
  }, [contextMenu.isVisible]);

  const getFolderOptions = target => {
    const options = [
      { label: 'New File', action: () => handleFileCreate(target) },
      { label: 'Rename', action: () => handleEditFolder(target) },
    ];

    // Only add the 'Delete' option if the folder has no documents
    if (target.documents.length === 0) {
      options.push({
        label: 'Delete',
        action: () => handleFolderDelete(target),
      });
    }

    return options;
  };

  const getFileOptions = target => [
    { label: 'Open', action: () => handleFileOpen(target) },
    { label: 'Rename', action: () => handleEditFile(target) },
    { label: 'Delete', action: () => handleFileDelete(target) },
  ];

  const showContextMenu = (event, target, type) => {
    event.preventDefault();
    const xPos = event.pageX + 'px';
    const yPos = event.pageY + 'px';

    const options =
      type === 'folder' ? getFolderOptions(target) : getFileOptions(target);

    setContextMenu({
      isVisible: true,
      position: { x: xPos, y: yPos },
      options: options,
      target: target,
    });

    setContextMenuTarget({ target, type });
  };

  const hideContextMenu = () => {
    setContextMenu(prevState => ({ ...prevState, isVisible: false }));
    setContextMenuTarget(null);
  };

  useEffect(() => {
    if (!hasSetHomeFolderOpen) {
      const homeFolder = folders.find(folder => folder.name === 'home');
      if (homeFolder) {
        setOpenFolders([homeFolder.id]);
        setHasSetHomeFolderOpen(true);
      }
    }
  }, [folders, hasSetHomeFolderOpen]);

  const toggleFolder = folderId => {
    if (!openFolders.includes(folderId)) {
      handleFolderSelect(folderId);
      setOpenFolders(prevOpenFolders => [...prevOpenFolders, folderId]);
    } else {
      setOpenFolders(prevOpenFolders =>
        prevOpenFolders.filter(id => id !== folderId)
      );
    }
  };

  const handleDrop = (folderId, event) => {
    event.preventDefault();
    event.stopPropagation();

    handleFileMove(draggingFile.current.id, folderId);

    if (!openFolders.includes(folderId)) {
      setOpenFolders([...openFolders, folderId]);
    }

    draggingFile.current = null;
  };

  const handleCloseAllFolders = () => {
    const homeFolder = folders.find(folder => folder.name === 'home');
    if (homeFolder) {
      setOpenFolders([homeFolder.id]);
    }
  };

  const EditInput = ({ initialValue, onSave, onCancel, id }) => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    const handleSave = () => {
      if (value !== initialValue) {
        onSave(value);
      }
      onCancel();
    };

    return (
      <input
        type="text"
        value={value}
        onChange={e => setValue(e.target.value)}
        onBlur={handleSave}
        onKeyDown={e => e.key === 'Enter' && handleSave()}
        autoFocus
        id={id}
      />
    );
  };

  const Folder = ({ folder }) => {
    const handleKeyDown = (event, action) => {
      if (event.key === 'Enter') {
        action();
      }
    };

    const handleAddFile = event => {
      event.stopPropagation();
      handleFileCreate(folder);
    };

    const handleDragStart = (event, doc) => {
      event.stopPropagation();
      draggingFile.current = doc;
    };

    const getDocumentClassName = doc => {
      let className = 'document-name';
      if (doc.id === activeFile?.id) className += ' active-file-highlight';
      if (editingFileId === doc.id) className += ' editing';

      if (
        contextMenuTarget?.type === 'document' &&
        contextMenuTarget?.target.id === doc.id
      ) {
        className += ' context-menu-active';
      }
      return className;
    };

    const handleDocumentKeyDown = doc => event => {
      handleKeyDown(event, () => handleFileOpen(doc));
    };

    const handleDocumentContextMenu = doc => event => {
      showContextMenu(event, doc, 'document');
    };

    const handleDocumentDragStart = doc => event => {
      handleDragStart(event, doc);
    };

    return (
      <div
        key={folder.id}
        onDrop={event => handleDrop(folder.id, event)}
        onDragOver={event => event.preventDefault()}
      >
        <div
          className={`folder-name ${
            editingFolderId === folder.id ? 'editing' : ''
          } ${
            contextMenuTarget?.type === 'folder' &&
            contextMenuTarget?.target.id === folder.id
              ? 'context-menu-active'
              : ''
          }`}
          onContextMenu={event => showContextMenu(event, folder, 'folder')}
          tabIndex="0"
        >
          {editingFolderId === folder.id ? (
            <EditInput
              initialValue={folder.name}
              onSave={newName => handleFolderRename(folder.id, newName)}
              onCancel={() => setEditingFolderId(null)}
              id={`folder-input-${folder.id}`}
            />
          ) : (
            <span onClick={() => toggleFolder(folder.id)}>
              {openFolders.includes(folder.id) ? (
                <FileDirectoryOpenFillIcon className="folder-blue" />
              ) : (
                <FileDirectoryFillIcon className="folder-blue" />
              )}
              &nbsp;{folder.name}
            </span>
          )}
          <button className="add-file-button" onClick={handleAddFile}>
            <PlusIcon size="12" />
          </button>
        </div>

        {openFolders.includes(folder.id) && (
          <div className="document-list">
            {folder.documents.map(doc => (
              <div
                key={doc.id}
                className={getDocumentClassName(doc)}
                draggable="true"
                onContextMenu={handleDocumentContextMenu(doc)}
                onDragStart={handleDocumentDragStart(doc)}
                onKeyDown={handleDocumentKeyDown(doc)}
                tabIndex="0"
              >
                {editingFileId === doc.id ? (
                  <EditInput
                    initialValue={doc.title}
                    onSave={newTitle => handleFileRename(doc.id, newTitle)}
                    onCancel={() => setEditingFileId(null)}
                    id={`file-input-${doc.id}`}
                  />
                ) : (
                  <>
                    <span onClick={() => handleFileOpen(doc)}>
                      <FileIcon className="explorer-file-icon" /> {doc.title}
                    </span>
                  </>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const homeFolder = folders.find(folder => folder.name === 'home');
  const otherFolders = folders.filter(folder => folder.name !== 'home');

  return (
    // prettier-ignore
    <div className="file-explorer">
      {homeFolder && <Folder folder={homeFolder} />}

      <div>
        <span style={{ float: 'left', fontWeight: 600, marginTop: '3px' }}>
          FOLDERS
        </span>
        <div className="button-container">
          <button className="new-folder-button" onClick={() => handleFolderCreate()}>
            <PlusIcon size="12" />
          </button>
          <button className="collapse-all-button" onClick={() => handleCloseAllFolders()}>
            <FoldIcon size="12" />
          </button>
        </div>
      </div>
      <hr style={{ marginTop: '5px' }} />
      {otherFolders.map(folder => (
        <Folder key={folder.id} folder={folder} />
      ))}

      {contextMenu.isVisible && (
        <ContextMenu
          x={contextMenu.position.x}
          y={contextMenu.position.y}
          options={contextMenu.options}
          isVisible={contextMenu.isVisible}
          onRequestClose={hideContextMenu}
        />
      )}
    </div>
  );
};

export default FileExplorer;
