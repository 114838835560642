import React, { useContext } from 'react';
import ReactDOM from 'react-dom';

import { NotificationContext } from '../contexts/NotificationContext';
import './NotificationModal.css';

const NotificationModal = () => {
  const { notification, hideNotification } = useContext(NotificationContext);
  const { messages, action } = notification;

  const modalRoot = document.getElementById('modal-root');

  const handleAction = () => {
    if (action) {
      action();
    }
  };

  if (!messages.length) return null; // Don't render if there's no message

  return ReactDOM.createPortal(
    <div
      className="notification-modal"
      style={{ display: 'flex', alignItems: 'flex-start' }}
    >
      <img
        src="/images/avatar.webp"
        alt="Avatar"
        style={{
          borderRadius: '50%',
          width: '50px',
          height: '50px',
          marginRight: '20px',
        }}
      />
      <div>
        {messages.map((message, index) => (
          <p key={index}>{message}</p>
        ))}
        {action && <button onClick={handleAction}>Action</button>}
      </div>
    </div>,
    modalRoot
  );
};

export default NotificationModal;
