// External Libraries
import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from 'react-router-dom';

// Internal Components
import AuthCallback from './AuthCallback';
import DocumentHandler from './DocumentHandler';
import Login from './Login';
import NotFound from './NotFound';

// Utilities
import { publicApiCall } from './utils/api';

// Constants
import { REFRESH_TOKEN_KEY, TOKEN_KEY } from './constants';

async function isDocumentPublic(username, directory, document) {
  try {
    const response = await publicApiCall(
      'GET',
      `/${username}/${directory}/${document}/public`
    );
    return response.isPublic;
  } catch (error) {
    return false;
  }
}

function AuthenticatedRoutes() {
  const navigate = useNavigate();
  const location = useLocation();

  const [isPublicDoc, setIsPublicDoc] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const isAuthenticated =
    !!localStorage.getItem(TOKEN_KEY) &&
    !!localStorage.getItem(REFRESH_TOKEN_KEY);

  // This function is used to handle authenticated routes in the application.
  // It checks if the user is authenticated and if the document they are trying to access is public.
  // If the user is not authenticated and the document is not public, it redirects the user to the login page.
  // It also handles the loading state while checking the document's public status.
  useEffect(() => {
    const checkPublicDoc = async () => {
      const [, username, directory, document] = location.pathname.split('/');
      if (username && directory && document) {
        const publicDoc = await isDocumentPublic(username, directory, document);
        setIsPublicDoc(publicDoc);
      }

      setIsLoading(false);
    };
    checkPublicDoc();
  }, [location]);

  // publicDoc will fire an update here as well isLoading changes.
  // If the user is not authenticated and we know for certain (isLoading) that
  // the document isn't public, then we redirect the user to login.
  useEffect(() => {
    if (!isAuthenticated && !isPublicDoc && !isLoading) {
      navigate('/login', { state: { from: location } });
    }
  }, [isAuthenticated, navigate, location, isPublicDoc, isLoading]);

  return (
    <Routes>
      <Route path="/:username" element={<DocumentHandler />} />
      <Route path="/:username/:directory" element={<DocumentHandler />} />
      <Route
        path="/:username/:directory/:document"
        element={<DocumentHandler />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="404" element={<NotFound />} />
        <Route path="/login" element={<Login />} />
        <Route path="/auth/github/callback" element={<AuthCallback />} />
        <Route path="/*" element={<AuthenticatedRoutes />} />
      </Routes>
    </Router>
  );
}

export default App;
