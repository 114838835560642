import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';

import { apiUrl } from '../utils/api';
import './SearchModal.css';

const SearchModal = ({ onFileSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [files, setFiles] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [selectedFileIndex, setSelectedFileIndex] = useState(0);
  const searchInputRef = useRef(null);

  const apiCall = async (method, url, data = null) => {
    const response = await axios({
      method,
      url: apiUrl(url),
      data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    return response.data;
  };

  useEffect(() => {
    const handleKeyDown = event => {
      switch (event.key) {
        case 'Escape':
          onFileSelect(null);
          break;
        case 'ArrowUp':
          setSelectedFileIndex(prevIndex =>
            prevIndex > 0 ? prevIndex - 1 : 0
          );
          break;
        case 'ArrowDown':
          setSelectedFileIndex(prevIndex =>
            prevIndex < filteredFiles.length - 1 ? prevIndex + 1 : prevIndex
          );
          break;
        case 'Enter':
          if (filteredFiles[selectedFileIndex]) {
            handleFileClick(filteredFiles[selectedFileIndex]);
          }
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [filteredFiles, selectedFileIndex]);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await apiCall('get', '/documents');
        setFiles(response);
      } catch (error) {
        console.error('Failed to fetch files:', error);
      }
    };

    fetchFiles();
  }, []);

  // Effect for searching files
  useEffect(() => {
    if (searchTerm) {
      const searchResults = files.filter(file =>
        file.filePath.includes(searchTerm)
      );
      setFilteredFiles(searchResults);
      if (searchResults.length === 1) {
        setSelectedFileIndex(0); // Automatically select the first item
      }
    } else {
      setFilteredFiles([]); // Clear search results when search term is cleared
      setSelectedFileIndex(0); // Reset selected index
    }
  }, [searchTerm, files]);

  const handleFileClick = file => {
    onFileSelect(file);
    setSearchTerm(''); // clear the search term
  };

  return (
    <div className="search-modal">
      <input
        ref={searchInputRef}
        type="text"
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        placeholder="Search for a file..."
      />
      <ul>
        {filteredFiles.map((file, index) => (
          <li
            key={file.id}
            onClick={() => handleFileClick(file)}
            onMouseOver={() => setSelectedFileIndex(index)}
            className={index === selectedFileIndex ? 'selected' : ''}
          >
            {file.filePath}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SearchModal;
