import React, { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate, useLocation } from 'react-router-dom';

import axios from 'axios';

import { apiUrl } from './utils/api';

import './AuthCallback.css';

const AUTH_URL = apiUrl('/auth/github/callback');
const TOKEN_KEY = 'token';
const REFRESH_TOKEN_KEY = 'refreshToken';

const authenticateWithBackend = async code => {
  try {
    const response = await axios.post(AUTH_URL, { code });
    const { accessToken, refreshToken } = response.data;

    localStorage.setItem(TOKEN_KEY, accessToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);

    const decoded = jwtDecode(accessToken);
    return decoded.username;
  } catch (error) {
    console.error('Error authenticating with backend:', error);
    throw error;
  }
};

function AuthCallback() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');
    if (code) {
      authenticateWithBackend(code)
        .then(username => navigate(`/${username}`))
        .catch(error => {
          // handle error, e.g. show a message to the user
        });
    }
  }, [location, navigate]);

  return (
    <div className="login-container">
      <img src="/images/avatar.webp" alt="Avatar" className="avatar" />
      <div className="login-button">Authenticating...</div>
    </div>
  );
}

export default AuthCallback;
