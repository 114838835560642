import React from 'react';
import {
  HeadingIcon,
  BoldIcon,
  ItalicIcon,
  QuoteIcon,
  CodeIcon,
  LinkIcon,
  ListOrderedIcon,
  ListUnorderedIcon,
  TasklistIcon,
} from '@primer/octicons-react';

import './MarkdownToolbar.css';

const MarkdownToolbar = ({ currentTab, onToolbarItemClick }) => {
  const toolbarItems = [
    {
      icon: <HeadingIcon />,
      value: '## ',
      applyFunction: selectedText => `## ${selectedText}`,
    },
    {
      icon: <BoldIcon />,
      value: '**bold**',
      applyFunction: selectedText => `**${selectedText}**`,
    },
    {
      icon: <ItalicIcon />,
      value: '_italic_',
      applyFunction: selectedText => `_${selectedText}_`,
    },
    {
      icon: <QuoteIcon />,
      value: '> quote',
      applyFunction: selectedText => `> ${selectedText}`,
    },
    {
      icon: <CodeIcon />,
      value: '`code`',
      applyFunction: selectedText => `\`\`\`\r\n${selectedText}\r\n\`\`\``,
    },
    {
      icon: <LinkIcon />,
      value: '[title](https://)',
      applyFunction: selectedText => `[${selectedText}](https://)`,
    },
    {
      icon: <ListOrderedIcon />,
      value: '1. ',
      applyFunction: selectedText => {
        const lines = selectedText.split('\n');
        return lines.map((line, index) => `${index + 1}. ${line}`).join('\n');
      },
    },
    {
      icon: <ListUnorderedIcon />,
      value: '- ',
      applyFunction: selectedText => {
        const lines = selectedText.split('\n');
        return lines.map(line => `- ${line}`).join('\n');
      },
    },
    {
      icon: <TasklistIcon />,
      value: '- [ ] ',
      applyFunction: selectedText => {
        const lines = selectedText.split('\n');
        return lines.map(line => `- [ ] ${line}`).join('\n');
      },
    },
  ];

  return (
    <div className="markdown-toolbar">
      {toolbarItems.map((item, index) => (
        <button
          key={index}
          className={`toolbar-button ${
            currentTab === 'preview' ? 'disabled' : ''
          }`}
          onClick={() => onToolbarItemClick(item.value, item.applyFunction)}
        >
          {item.icon}
        </button>
      ))}
    </div>
  );
};

export default MarkdownToolbar;
