// React imports
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Utility imports
import { debounce } from 'lodash';

// Context imports
import { useFileExplorer } from './contexts/FileExplorerContext';

// API and utility imports
import {
  apiCall,
  convertPermissionsToYAML,
  convertYAMLToPermissions,
} from './utils/api';

// Component imports
import Editor from './components/Editor';
import EditorTopRibbon from './components/EditorTopRibbon';
import FileExplorer from './components/FileExplorer';
import MarkdownPreview from './components/MarkdownPreview';
import SearchModal from './components/SearchModal';
import Toolbar from './components/Toolbar';

// CSS imports
import '@primer/css/dist/primer.css';
import './EditorShell.css';

function EditorShell() {
  // File explorer context
  const {
    activeFile,
    folders,
    handleFileOpen,
    setActiveFile,
    setSelectedFolderId,
    setFolders,
  } = useFileExplorer();

  // What's our user after?
  const { directory, document } = useParams();

  // UI state
  const [currentTab, setCurrentTab] = useState('edit');
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [isPermissionsEditMode, setIsPermissionsEditMode] = useState(false);
  const [permissionsContent, setPermissionsContent] = useState('');

  // Synchronization state
  const [saveQueue, setSaveQueue] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  // Toolbar <-> Editor interaction state
  const [applyFunction, setApplyFunction] = useState(null);

  // Fetch folders from API
  const fetchFolders = async () => {
    try {
      const data = await apiCall('get', 'folders');
      setFolders(data);
    } catch (error) {
      console.error('Error fetching folders', error);
    }
  };

  // Process the save queue
  const updateActiveFile = (content, version, fileId) => {
    if (activeFile && activeFile.id === fileId) {
      setActiveFile(prevFile => ({
        ...prevFile,
        content,
        version,
      }));
    }
  };

  const handleSaveError = error => {
    if (error.response && error.response.status === 409) {
      updateActiveFile(
        error.response.data.latestContent,
        error.response.data.latestVersion
      );
    } else {
      console.error('Error saving document:', error);
    }
  };

  const processSaveQueue = useCallback(async () => {
    if (saveQueue.length > 0 && !isSaving) {
      setIsSaving(true);
      const { documentId, content, version } = saveQueue[0];

      try {
        const response = await apiCall('patch', `documents/${documentId}`, {
          content,
          version,
        });

        updateActiveFile(content, response.newVersion, documentId);
      } catch (error) {
        handleSaveError(error);
      } finally {
        setSaveQueue([]);
        setIsSaving(false);
      }
    }
  }, [isSaving, saveQueue]);

  // Keyboard shortcuts
  useEffect(() => {
    const handleKeyDown = event => {
      if (event.ctrlKey && event.shiftKey && event.key === 'T') {
        setIsSearchModalOpen(true);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // Reset the canvas when activeFile is changed or updated
  useEffect(() => {
    if (activeFile) {
      setIsPermissionsEditMode(false);
      setPermissionsContent('');
    }
  }, [activeFile]);

  // Fetch folders on load
  useEffect(() => {
    fetchFolders();
  }, []);

  // Process save queue when it changes
  useEffect(() => {
    processSaveQueue();
  }, [processSaveQueue]);

  const loadDocument = async (effectiveDirectory, effectiveDocument) => {
    const endpoint = `folders/${encodeURIComponent(
      effectiveDirectory
    )}/documents/${encodeURIComponent(effectiveDocument)}`;

    return await apiCall('get', endpoint).catch(error => {
      console.error('Error loading document:', error);
      return null;
    });
  };

  const findFallbackFolder = effectiveDirectory => {
    return folders.find(folder => folder.name === effectiveDirectory);
  };

  useEffect(() => {
    const loadDocumentByName = async () => {
      const effectiveDirectory = directory || 'home';
      const effectiveDocument = document || 'README';

      const data = await loadDocument(effectiveDirectory, effectiveDocument);

      if (data) {
        setActiveFile(data);
        setSelectedFolderId(data.folderId);
        setCurrentTab('edit');
      } else {
        const fallbackFolder = findFallbackFolder(effectiveDirectory);
        if (fallbackFolder) setSelectedFolderId(fallbackFolder.id);
      }
    };
    loadDocumentByName();
  }, [directory, document]);

  const togglePermissionsEditMode = async () => {
    if (!isPermissionsEditMode) {
      const permissions = await apiCall(
        'get',
        `documents/${activeFile.id}/permissions`
      );
      setPermissionsContent(convertPermissionsToYAML(permissions));
    }
    setIsPermissionsEditMode(!isPermissionsEditMode);
  };

  const handleFilePermissionsUpdate = async () => {
    const permissionsData = convertYAMLToPermissions(permissionsContent);
    await apiCall(
      'patch',
      `documents/${activeFile.id}/permissions`,
      permissionsData
    );
    setIsPermissionsEditMode(false);
  };

  const debouncedSetSaveQueue = useCallback(
    debounce(newQueue => {
      setSaveQueue(newQueue);
    }, 2000),
    []
  );

  const enqueueSaveOperation = (documentId, content, version) => {
    debouncedSetSaveQueue([{ documentId, content, version }]);
  };

  const handleEditorChange = newContent => {
    if (activeFile) {
      activeFile.content = newContent;
      //setActiveFile({ ...activeFile, content: newContent });
      enqueueSaveOperation(activeFile.id, newContent, activeFile.version);
    }
  };

  const handleTabChange = tab => {
    setCurrentTab(tab);
  };

  const handleToolbarItemClick = (value, applyFunction) => {
    setApplyFunction(() => applyFunction);
  };

  const nullifyApplyFunction = () => {
    setApplyFunction(null);
  };

  const permissionsFile = {
    title: 'permissions.yaml',
    content: permissionsContent,
  };

  return (
    <div className="EditorShell base-document-view">
      <Toolbar />
      <div className="main-container">
        <FileExplorer />
        <div className="editor-container">
          {activeFile && (
            <>
              <EditorTopRibbon
                currentTab={currentTab}
                file={activeFile}
                onClose={() => setActiveFile(null)}
                onTabChange={handleTabChange}
                onToolbarItemClick={handleToolbarItemClick}
                onSavePermissions={handleFilePermissionsUpdate}
                onTogglePermissions={togglePermissionsEditMode}
                isPermissionsEditMode={isPermissionsEditMode}
              />
              {isPermissionsEditMode ? (
                <Editor
                  mode="yaml"
                  file={permissionsFile}
                  onContentChange={setPermissionsContent}
                />
              ) : currentTab === 'edit' ? (
                <Editor
                  applyFunction={applyFunction}
                  file={activeFile}
                  key={activeFile.id}
                  onContentChange={handleEditorChange}
                  nullifyApplyFunction={nullifyApplyFunction}
                />
              ) : (
                <div className="markdown-preview">
                  <MarkdownPreview file={activeFile} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {isSearchModalOpen && (
        <SearchModal
          style={{ display: isSearchModalOpen ? 'block' : 'none' }}
          onFileSelect={file => {
            if (file != null) {
              handleFileOpen(file);
            }
            setIsSearchModalOpen(false);
          }}
        />
      )}
    </div>
  );
}

export default EditorShell;
