import React, { useEffect, useRef } from 'react';

import './Drawer.css';

const Drawer = ({ onClose }) => {
  const drawerRef = useRef();

  useEffect(() => {
    const handleClickOutside = event => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const logout = () => {
    // Remove the token from local storage
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');

    // Optionally, redirect the user to the login page
    window.location.href = '/login';
  };

  return (
    <div className="drawer" ref={drawerRef}>
      <button className="close-button" onClick={onClose}>
        X
      </button>{' '}
      {/* The close button */}
      <button onClick={logout}>Logout</button>
    </div>
  );
};

export default Drawer;
